import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "./Content.css";
import Logo_CyberPro from "../Media/Logo_products/Logo_CyberPro.svg"
import Logo_Avor from "../Media/Logo_products/Logo_Avor.svg"
import Logo_LateProtection from "../Media/Logo_products/Logo_LateProtection.svg"
import Logo_ProtectionStation from "../Media/Logo_products/Logo_ProtectionStation.svg"
import Logo_CloverMoney from "../Media/Logo_products/Logo_CloverMoney.svg"
import Logo_UnisafeSmart from "../Media/Logo_products/Logo_UnisafeSmart.svg"
import Logo_UnisafeTech from "../Media/Logo_products/Logo_UnisafeTech.svg"
import Logo_KidsProtection from "../Media/Logo_products/Logo_KidsProtection.svg"
import Logo_ALock from "../Media/Logo_products/Logo_ALock.svg"
import Logo_HiTechProtection from "../Media/Logo_products/Logo_HiTechProtection.svg"
import Logo_UniCare from "../Media/Logo_products/Logo_UniCare.svg"
import Logo_UniCarePlus from "../Media/Logo_products/Logo_UniCarePlus.svg"

import TelegramSupport from "../Media/TelegramSupport.png"

import Logo_Android from "../Media/Logo_Android.svg";
import Logo_Windows from "../Media/Logo_Windows.svg";
import Logo_AppStore from "../Media/Logo_AppStore.svg";
import Installation from "../Media/Installation.pdf";
import Specifications from "../Media/Specifications.pdf";
import License from "../Media/License.pdf";
import Offer from "../Media/Offer.pdf";

import AvorPlusApk from "../Media/Usafe.apk";
import AvorApk from "../Media/avor.apk";
import AntiVoriAntiVirus from "../Media/AntiVorAntiVirus.apk";

import TestImg from "../Media/Imagine_Products/TEST.png"
import ProtectionStationImg from "../Media/Imagine_Products/ProtectionStationImg.png"

import Logo_MTS_BW from "../Media/Logo_partners/Logo_MTS_BW.svg";
import Logo_MegaFon_BW from "../Media/Logo_partners/Logo_MegaFon_BW.svg";
import Logo_Beeline_BW from "../Media/Logo_partners/Logo_Beeline_BW.svg";
import Logo_Tele2_BW from "../Media/Logo_partners/Logo_Tele2_BW.svg";
import Logo_Sber_BW from "../Media/Logo_partners/Logo_Sber_BW.svg";
import Logo_Alfa_BW from "../Media/Logo_partners/Logo_Alfa_BW.svg";
import Logo_ATB_BW from "../Media/Logo_partners/Logo_ATB_BW.svg";
import Logo_OTP_BW from "../Media/Logo_partners/Logo_OTP_BW.svg";
import Logo_MVideo_BW from "../Media/Logo_partners/Logo_MVideo_BW.svg";
import Logo_Eldorado_BW from "../Media/Logo_partners/Logo_Eldorado_BW.svg";
import Logo_Mechta_BW from "../Media/Logo_partners/Logo_Mechta_BW.svg";
import Logo_Evrika_BW from "../Media/Logo_partners/Logo_Evrika_BW.svg";
import Logo_Samolet_BW from "../Media/Logo_partners/Logo_Samolet_BW.svg";
import Logo_NowHow_BW from "../Media/Logo_partners/Logo_NowHow_BW.svg";

function FileDownloader({ url, href, fileName, imgName, text, Type}) {
    const handleClick = () => {
        const link = document.createElement('a');
        // Определяем, какой атрибут использовать для скачивания файла
        link.href = url || href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const buttonClasses = {
        1: "Download_button_1",
        2: "Download_button_2",
        3: "Download_button_3",
    };

    const buttonClass = buttonClasses[Type]

    return (
        <button onClick={handleClick} className={buttonClass}>
            {imgName && <img src={imgName} alt={fileName} className="Download_OS"/>}
            {text && <p>{text}</p>}
        </button>
    );
}

function ButtonGlow({text}) {
    return (
        <button className='Download_button_3'>
            {text && <p>{text}</p>}
        </button>
    );
}

function LogoWithBack({Image, Title}) {

    return (
        <div className="Partners_block">
            <div></div> {/*это что бы наложить фильтры если что*/}
            <img src={Image} title={Title}/>
        </div>
    )
}
function Contact ({Link, Info, Title}) {
    return(
        <div className="Contacts">
            <a href={Link}>{Info}</a>
            <p>{Title}</p>
        </div>
        )
}


export function Content_test ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('TEST.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >
                    <h2 className="Subtitle">{t('TEST.Subtitle')}</h2>
                    <img src={TestImg} className="Image"/>
                    <p className="Text_description">{t('TEST.Description')}</p>
                    <p className="Text_body">{t('TEST.Body')}</p>
                    <ul className="List Text_body">
                        <li>{t('TEST.List_1')}</li>
                        <li>{t('TEST.List_2')}</li>
                        <li>{t('TEST.List_3')}</li>
                    </ul>

                    {Price && (
                        <div className="Download">
                            <div className="Download_flex" style={{justifyContent: 'flex-start'}}>
                                <img src={Logo_CyberPro} className="Download_Logo"/>
                                <div className="Buy" style={{margin: '0'}}>
                                    <h1>{t('General.Download')}</h1>
                                    <h2>{Price[0]}&nbsp;&nbsp;&nbsp;{Price[1]}</h2>
                                </div>
                            </div>
                            <div className="Download_flex" style={{justifyContent: 'space-between'}}>
                                <FileDownloader url="https://cyberprot.ru/antivirus/v2/apk" fileName="Android" imgName={Logo_Android} Type={1}/>
                                <FileDownloader url="https://cyberprot.ru/antivirus/v2/exe" fileName="Winodws" imgName={Logo_AppStore} Type={1}/>
                                <FileDownloader url="https://cyberprot.ru/antivirus/v2/exe" fileName="Winodws" imgName={Logo_Windows} Type={1}/>
                            </div>
                            <FileDownloader href={Installation} text="Инструкция по установке" Type={2}/>
                            <FileDownloader href={Specifications} text="Технические характеристики" Type={2}/>
                            <FileDownloader href={License} text="Лицензионное соглашение" Type={2}/>
                        </div>
                    )}

                    {Price && (
                        <div className="Buy">
                            <h1>{t('General.Download')}</h1>
                            <h2>{Price[0]}</h2>
                            <h2>{Price[1]}</h2>
                        </div>
                    )}
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}


export function CyberProtection ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">

            <a name="Servises" className="Link" style={{position: "absolute", top: '-100px'}}></a>
            <h1 className="Heading">{t('CyberProtection.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <h2 className="Subtitle">{t('CyberProtection.Subtitle')}</h2>
                <ul className="List Text_body">
                    <li>{t('CyberProtection.List_1')}</li>
                    <li>{t('CyberProtection.List_2')}</li>
                    <li>{t('CyberProtection.List_3')}</li>
                    <li>{t('CyberProtection.List_4')}</li>
                    <li>{t('CyberProtection.List_5')}</li>
                    <li>{t('CyberProtection.List_6')}</li>
                </ul>

                {Price && (
                    <div className="Download">
                        <div className="Download_flex" style={{justifyContent: 'flex-start'}}>
                            <img src={Logo_CyberPro} className="Download_Logo"/>
                            <div className="Buy" style={{margin: '0'}}>
                                <h1>{t('General.Download')}</h1>
                                <h2>{Price[0]} {t('General.Price_1')}&nbsp;&nbsp;&nbsp;{Price[1]} {t('General.Price_2')}</h2>
                            </div>
                        </div>
                        <div className="Download_flex" style={{justifyContent: 'space-between'}}>
                            <FileDownloader url="https://cyberprot.ru/antivirus/v2/apk" fileName="Android" imgName={Logo_Android} Type={1}/>
                            <FileDownloader url="https://apps.apple.com/app/unisafe-cyber-protection/id6503043164" fileName="iOS" imgName={Logo_AppStore} Type={1}/>
                            <FileDownloader url="https://cyberprot.ru/antivirus/v2/exe" fileName="Winodws" imgName={Logo_Windows} Type={1}/>
                        </div>
                        <FileDownloader href={Installation} text={t('CyberProtection.Button_1')} Type={2}/>
                        <FileDownloader href={Specifications} text={t('CyberProtection.Button_2')} Type={2}/>
                        <FileDownloader href={License} text={t('CyberProtection.Button_3')} Type={2}/>
                    </div>
                )}
            </div>

            {TypeDesign && (
                <div className="Design">
                    <Design TypeDesign={TypeDesign} Link={Link}/>
                </div>
            )}
        </div>
    )
}

export function Cyber ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('Cyber.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <p className="Text_description">{t('Cyber.Description')}</p>

                    <div className="Download">
                        <div className="Download_flex" style={{justifyContent: 'space-between'}}>
                            <FileDownloader url="https://cyberprot.ru/antivirus/v2/apk" fileName="Android" imgName={Logo_Android} Type={1}/>
                            <FileDownloader url="https://apps.apple.com/app/unisafe-cyber-protection/id6503043164" fileName="iOS" imgName={Logo_AppStore} Type={1}/>
                            <FileDownloader url="https://cyberprot.ru/antivirus/v2/exe" fileName="Winodws" imgName={Logo_Windows} Type={1}/>
                        </div>
                    </div>
            </div>

            {TypeDesign && (
                <div className="Design">
                    <Design TypeDesign={TypeDesign} Link={Link}/>
                </div>
            )}
        </div>
    )
}

export function CyberProtectionPartners ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;
    const [isExpanded1, setIsExpanded1] = useState(false);
    const [isExpanded2, setIsExpanded2] = useState(false);
    const [isExpanded3, setIsExpanded3] = useState(false);
    const toggleContent1 = () => {setIsExpanded1(!isExpanded1);};
    const toggleContent2 = () => {setIsExpanded2(!isExpanded2);};
    const toggleContent3 = () => {setIsExpanded3(!isExpanded3);};

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('CyberProtection.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <h2 className="Subtitle">{t('CyberProtection.Subtitle')}</h2>
                <ul className="List Text_body">
                    <li>{t('CyberProtection.List_1')}</li>
                    <li>{t('CyberProtection.List_2')}</li>
                    <li>{t('CyberProtection.List_3')}</li>
                    <li>{t('CyberProtection.List_4')}</li>
                    <li>{t('CyberProtection.List_5')}</li>
                    <li>{t('CyberProtection.List_6')}</li>
                </ul>

                {Price && (
                    <div className="Download">
                        <div className="Download_flex" style={{justifyContent: 'flex-start'}}>
                            <img src={Logo_CyberPro} className="Download_Logo"/>
                            <div className="Buy" style={{margin: '0'}}>
                                <h1>{t('General.Tariffs')}</h1>
                                <h2>{Price[0]}&nbsp;&nbsp;&nbsp;{Price[1]}</h2>
                            </div>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <button onClick={toggleContent1} className='Download_button_3'>
                                <p>Скачать Cyber Protection</p>
                            </button>
                            <div className={`content ${isExpanded1 ? "show" : ""}`} style={{margin: 'auto'}}>
                                <div className="Download_flex"></div>
                                <div className="Download_flex" style={{justifyContent: 'space-between', marginTop: '20px'}}>
                                    <FileDownloader url="https://cyberprot.ru/antivirus/v2/apk" fileName="Android" imgName={Logo_Android} Type={1}/>
                                    <FileDownloader url="https://apps.apple.com/app/unisafe-cyber-protection/id6503043164" fileName="iOS" imgName={Logo_AppStore} Type={1}/>
                                    <FileDownloader url="https://cyberprot.ru/antivirus/v2/exe" fileName="Winodws" imgName={Logo_Windows} Type={1}/>
                                </div>
                            </div>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <button onClick={toggleContent2} className='Download_button_3'>
                                <p>Скачать КиберПро</p>
                            </button>
                            <div className={`content ${isExpanded2 ? "show" : ""}`} style={{margin: 'auto'}}>
                                <div className="Download_flex"></div>
                                <div className="Download_flex" style={{justifyContent: 'space-between', marginTop: '20px', width: '95%'}}>
                                    <FileDownloader url="https://cyberprot.ru/antivirus/v2/apk" fileName="Android" imgName={Logo_Android} Type={1}/>
                                    <FileDownloader url="https://apps.apple.com/app/unisafe-cyber-protection/id6503043164" fileName="iOS" imgName={Logo_AppStore} Type={1}/>
                                    <FileDownloader url="https://cyberprot.ru/antivirus/v2/exe" fileName="Winodws" imgName={Logo_Windows} Type={1}/>
                                </div>
                            </div>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <button onClick={toggleContent3} className='Download_button_3'>
                                <p>Скачать Кибер защита</p>
                            </button>
                            <div className={`content ${isExpanded3 ? "show" : ""}`} style={{margin: 'auto'}}>
                                <div className="Download_flex"></div>
                                <div className="Download_flex" style={{justifyContent: 'space-between', marginTop: '20px', width: '95%'}}>
                                    <FileDownloader url="https://cyberprot.ru/antivirus/v2/apk" fileName="Android" imgName={Logo_Android} Type={1}/>
                                    <FileDownloader url="https://apps.apple.com/app/unisafe-cyber-protection/id6503043164" fileName="iOS" imgName={Logo_AppStore} Type={1}/>
                                    <FileDownloader url="https://cyberprot.ru/antivirus/v2/exe" fileName="Winodws" imgName={Logo_Windows} Type={1}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {TypeDesign && (
                <div className="Design">
                    <Design TypeDesign={TypeDesign} Link={Link}/>
                </div>
            )}
        </div>
    )
}

export function CyberProtectionFAQ ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('CyberProtectionFAQ.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <h2 className="Subtitle">{t('CyberProtectionFAQ.Subtitle')}</h2>

                <ul className="List Text_body">
                    <li>{t('CyberProtectionFAQ.Question1')}<br/>
                        {t('CyberProtectionFAQ.Answer1')}
                    </li>
                    <li>{t('CyberProtectionFAQ.Question2')}<br/>
                        <a className="Text_body" style={{textDecoration: 'none', border: '1px solid white', borderRadius: '2px'}} href={"https://usafe.ru/CyberProtectionSupport"}>{t('CyberProtectionFAQ.Answer2')}</a>
                    </li>
                    <li>{t('CyberProtectionFAQ.Question3')}<br/>
                        {t('CyberProtectionFAQ.Answer3')}
                    </li>
                    <li>{t('CyberProtectionFAQ.Question4')}<br/>
                        {t('CyberProtectionFAQ.Answer4')}
                    </li>
                </ul>

            </div>

            {TypeDesign && (
                <div className="Design">
                    <Design TypeDesign={TypeDesign} Link={Link}/>
                </div>
            )}
        </div>
    )
}

export function CyberProtectionSupport ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('CyberProtectionSupport.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <h2 className="Subtitle">{t('CyberProtectionSupport.Subtitle')}</h2>

                <img src={TelegramSupport} className="Image"/>

                <div className="Content_block" style={{ marginLeft: margin}} >
                    <Contact Link={t('CyberProtectionSupport.Link_1')} Info={t('CyberProtectionSupport.Indicator_1')} Title={t('CyberProtectionSupport.Label_1')}/>
                    <Contact Link={t('CyberProtectionSupport.Link_2')} Info={t('CyberProtectionSupport.Indicator_2')} Title={t('CyberProtectionSupport.Label_2')}/>
                    <Contact Link={t('CyberProtectionSupport.Link_3')} Info={t('CyberProtectionSupport.Indicator_3')} Title={t('CyberProtectionSupport.Label_3')}/>
                </div>

            </div>

            {TypeDesign && (
                <div className="Design">
                    <Design TypeDesign={TypeDesign} Link={Link}/>
                </div>
            )}
        </div>
    )
}

export function AntiVorAntiVirus ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('AntiVorAntiVirus.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >
                {/*<img src={AvorImg} className="Image"/>*/}
                <p className="Text_description">{t('AntiVorAntiVirus.Description_1')}</p>
                <p className="Text_description">{t('AntiVorAntiVirus.Description_2')}</p>
                <p className="Text_description">{t('AntiVorAntiVirus.Description_3')}</p>

                {Price && (
                    <div className="Download">
                        <div className="Download_flex" style={{justifyContent: 'flex-start'}}>
                            <img src={Logo_Avor} className="Download_Logo"/>
                            <div className="Buy" style={{margin: '0'}}>
                                <h1>{t('General.Download')}</h1>
                                <h2>{Price[0]} {t('General.Price_2')}</h2>
                            </div>
                        </div>
                        <div className="Download_flex" style={{justifyContent: 'space-between'}}>
                            <FileDownloader href={AntiVoriAntiVirus} fileName="Android" imgName={Logo_Android} Type={1}/>
                        </div>
                        <FileDownloader url="https://t.me/antivor_unisafe_bot" text={t('AntiVorAntiVirus.Title_3')} Type={2}/>
                        <FileDownloader href={Offer} text={t('AntiVorAntiVirus.Title_4')} Type={2}/>
                    </div>
                )}
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function ProtectionStation ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('ProtectionStation.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <h2 className="Subtitle">{t('ProtectionStation.Subtitle')}</h2>
                <img src={ProtectionStationImg} className="Image" style={{width: '50%'}}/>
                <ul className="List Text_body">
                    <li>{t('ProtectionStation.List_1')}</li>
                    <li>{t('ProtectionStation.List_2')}</li>
                    <li>{t('ProtectionStation.List_3')}</li>
                    <li>{t('ProtectionStation.List_4')}</li>
                    <li>{t('ProtectionStation.List_5')}</li>
                    <li>{t('ProtectionStation.List_6')}</li>
                    <li>{t('ProtectionStation.List_7')}</li>
                    <li>{t('ProtectionStation.List_8')}</li>
                </ul>
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function Avor ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('Avor.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >
                {/*<img src={AvorImg} className="Image"/>*/}
                <p className="Text_description">{t('Avor.Description')}</p>

                {Price && (
                    <div className="Download">
                        <div className="Download_flex" style={{justifyContent: 'flex-start'}}>
                            <img src={Logo_Avor} className="Download_Logo"/>
                            <div className="Buy" style={{margin: '0'}}>
                                <h1>{t('General.Download')}</h1>
                                <h2>{Price[0]} {t('General.Price_2')}</h2>
                            </div>
                        </div>
                        <div className="Download_flex" style={{justifyContent: 'space-between'}}>
                            <FileDownloader href={AvorPlusApk} fileName="Android" imgName={Logo_Android} Type={1}/>
                        </div>
                        <p className="Text_Help" style={{marginBottom: "30px"}}>{t('Avor.Title_1')}</p>
                        <div className="Download_flex" style={{justifyContent: 'space-between'}}>
                            <FileDownloader href={AvorApk} fileName="Android" imgName={Logo_Android} Type={1}/>
                            <FileDownloader url="https://apps.apple.com/ru/app/unisafe/id1456992392" fileName="iOS" imgName={Logo_AppStore} Type={1}/>
                        </div>
                        <p className="Text_Help" style={{marginBottom: "30px"}}>{t('Avor.Title_2')}</p>
                        <FileDownloader url="https://t.me/antivor_unisafe_bot" text={t('Avor.Title_3')} Type={2}/>
                        <FileDownloader href={Offer} text={t('Avor.Title_4')} Type={2}/>
                    </div>
                )}
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function AvorDownloadUnisafe ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('AvorDownloadUnisafe.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >
                <p className="Text_description">{t('AvorDownloadUnisafe.Description')}</p>
                    <div className="Download">
                        <div className="Download_flex" style={{justifyContent: 'space-between'}}>
                            <FileDownloader href={AvorPlusApk} fileName="Android" imgName={Logo_Android} Type={1}/>
                        </div>
                    </div>
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function AvorDownloadUsafe ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('AvorDownloadUsafe.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >
                <p className="Text_description">{t('AvorDownloadUsafe.Description')}</p>
                <div className="Download">
                    <div className="Download_flex" style={{justifyContent: 'space-between'}}>
                        <FileDownloader href={AvorPlusApk} fileName="Android" imgName={Logo_Android} Type={1}/>
                    </div>
                </div>
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function AvorDownloadProtect ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('AvorDownloadProtect.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >
                <p className="Text_description">{t('AvorDownloadProtect.Description')}</p>
                <div className="Download">
                    <div className="Download_flex" style={{justifyContent: 'space-between'}}>
                        <FileDownloader href={AvorPlusApk} fileName="Android" imgName={Logo_Android} Type={1}/>
                    </div>
                </div>
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function AvorInstructions ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('AvorInstructions.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <ul className="List Text_body">
                    <li><strong>{t('AvorInstructions.Title_1')}</strong><br/>
                        <span>{t('AvorInstructions.Description_1')}</span></li>

                    <li><strong>{t('AvorInstructions.Title_2')}</strong><br/>
                        <span>{t('AvorInstructions.Description_2')}</span></li>

                    <li><strong>{t('AvorInstructions.Title_3')}</strong><br/>
                        <span>{t('AvorInstructions.Description_3')}</span></li>

                    <li><strong>{t('AvorInstructions.Title_4')}</strong><br/>
                        <span>{t('AvorInstructions.Description_4')}</span></li>

                    <li><strong>{t('AvorInstructions.Title_5')}</strong><br/>
                        <span>{t('AvorInstructions.Description_5')}</span></li>

                    <li><strong>{t('AvorInstructions.Title_6')}</strong><br/>
                        <span>{t('AvorInstructions.Description_6')}</span></li>

                    <li><strong>{t('AvorInstructions.Title_7')}</strong><br/>
                        <span>{t('AvorInstructions.Description_7')}</span></li>
                </ul>
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function KidsProtection ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('KidsProtection.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <img src={Logo_KidsProtection} className="Product_Logo"/>
                <p className="Text_description">{t('KidsProtection.Description')}</p>

                {Price && (
                    <div className="Download">
                        <div className="Download_flex" style={{justifyContent: 'flex-start'}}>
                            <div className="Buy" style={{margin: '0'}}>
                                <h1>{t('General.Get')}</h1>
                                <h2>{Price[0]} {t('General.Price_1')}&nbsp;&nbsp;&nbsp;{Price[1]} {t('General.Price_2')}</h2>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function LateProtection ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('LateProtection.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <h2 className="Subtitle">{t('LateProtection.Subtitle')}</h2>
                <p className="Text_body">{t('LateProtection.Body')}</p>

                {Price && (
                    <div className="Download">
                        <div className="Download_flex" style={{justifyContent: 'flex-start'}}>
                            <img src={Logo_LateProtection} className="Download_Logo"/>
                            <div className="Buy" style={{margin: '0'}}>
                                <h1>{t('General.Get')}</h1>
                                <h2>{Price[0]} {t('General.Price_1')}</h2>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function HiTechProtection ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('HiTechProtection.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <h2 className="Subtitle">{t('HiTechProtection.Subtitle')}</h2>
                <img src={Logo_HiTechProtection} className="Product_Logo"/>
                <p className="Text_body">{t('HiTechProtection.Body')}</p>
                <ul className="List Text_body">
                    <li>{t('HiTechProtection.List_1')}</li>
                    <li>{t('HiTechProtection.List_2')}</li>
                    <li>{t('HiTechProtection.List_3')}</li>
                </ul>

                {Price && (
                    <div className="Download">
                        <div className="Download_flex" style={{justifyContent: 'flex-start'}}>
                            <div className="Buy" style={{margin: '0'}}>
                                <h1>{t('General.Get')}</h1>
                                <h2>{Price[0]} {t('General.Price_1')}</h2>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function SalesTechnology ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('SalesTechnology.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <h2 className="Subtitle">{t('SalesTechnology.Subtitle')}</h2>
                <p className="Text_body">{t('SalesTechnology.Body')}</p>
                <ul className="List Text_body">
                    <li>{t('SalesTechnology.List_1')}
                        <ul className="List">
                            <li>{t('SalesTechnology.List_1_1')}</li>
                            <li>{t('SalesTechnology.List_1_2')}</li>
                        </ul>
                    </li>
                    <li>{t('SalesTechnology.List_2')}
                        <ul className="List">
                            <li>{t('SalesTechnology.List_2_1')}</li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function UniCare ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('UniCare.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <h2 className="Subtitle">{t('UniCare.Subtitle')}</h2>
                <p className="Text_body">{t('UniCare.Body')}</p>
                <ul className="List Text_body">
                    <li>{t('UniCare.List_1')}</li>
                    <li>{t('UniCare.List_2')}</li>
                    <li>{t('UniCare.List_3')}</li>
                </ul>

                {Price && (
                    <div className="Download">
                        <div className="Download_flex" style={{justifyContent: 'flex-start'}}>
                            <img src={Logo_UniCare} className="Download_Logo"/>
                            <div className="Buy" style={{margin: '0'}}>
                                <h1>{t('General.Get')}</h1>
                                <h2>{Price[0]} {t('General.Price_1')}</h2>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function SavePrecious ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('SavePrecious.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <h2 className="Subtitle">{t('SavePrecious.Subtitle')}</h2>
                <p className="Text_body">{t('SavePrecious.Body_1')}</p>
                <ul className="List Text_body">
                    <li>{t('SavePrecious.List_1')}</li>
                    <li>{t('SavePrecious.List_2')}</li>
                </ul>
                <p className="Text_body">{t('SavePrecious.Body_2')}</p>
                <ul className="List Text_body">
                    <li>{t('SavePrecious.List_3')}</li>
                    <li>{t('SavePrecious.List_4')}</li>
                </ul>
                <p className="Text_body">{t('SavePrecious.Body_3')}</p>
                <ul className="List Text_body">
                    <li>{t('SavePrecious.List_5')}</li>
                </ul>

                <p className="Text_description">{t('SavePrecious.Description')}</p>

                {Price && (
                    <div className="Buy">
                        <h1>{t('General.Get')}</h1>
                        <h2>{Price[0]} {t('General.Price_1')}</h2>
                        <h2>{Price[1]} {t('General.Price_2')}</h2>
                    </div>
                )}
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function UniCarePlus ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('UniCarePlus.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <h2 className="Subtitle">{t('UniCarePlus.Subtitle')}</h2>
                <p className="Text_body">{t('UniCarePlus.Body')}</p>
                <ul className="List Text_body">
                    <li>{t('UniCarePlus.List_1')}</li>
                    <li>{t('UniCarePlus.List_2')}</li>
                    <li>{t('UniCarePlus.List_3')}</li>
                    <li>{t('UniCarePlus.List_4')}</li>
                </ul>

                {Price && (
                    <div className="Download">
                        <div className="Download_flex" style={{justifyContent: 'flex-start'}}>
                            <img src={Logo_UniCarePlus} className="Download_Logo"/>
                            <div className="Buy" style={{margin: '0'}}>
                                <h1>{t('General.Get')}</h1>
                                <h2>{Price[0]} {t('General.Price_1')}</h2>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function ALock ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('ALock.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <h2 className="Subtitle">{t('ALock.Subtitle')}</h2>
                <p className="Text_body">{t('ALock.Body')}</p>
                <ul className="List Text_body">
                    <li>{t('ALock.List_1')}</li>
                    <li>{t('ALock.List_2')}</li>
                    <li>{t('ALock.List_3')}</li>
                    <li>{t('ALock.List_4')}</li>
                </ul>

                {Price && (
                    <div className="Download">
                        <div className="Download_flex" style={{justifyContent: 'flex-start'}}>
                            <img src={Logo_ALock} className="Download_Logo"/>
                            <div className="Buy" style={{margin: '0'}}>
                                <h1>{t('General.Get')}</h1>
                                <h2>{Price[0]} {t('General.Price_1')}&nbsp;&nbsp;&nbsp;{Price[1]} {t('General.Price_2')}</h2>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function UniSafeSmart ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('UniSafeSmart.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <h2 className="Subtitle">{t('UniSafeSmart.Subtitle')}</h2>
                <img src={Logo_UnisafeSmart} className="Product_Logo"/>

                <p className="Text_body">{t('UniSafeSmart.Body')}</p>
                <ul className="List Text_body">
                    <li>
                        <p style={{fontWeight: "bold"}}>{t('UniSafeSmart.List_1')}</p>
                        <p>{t('UniSafeSmart.List_2')}</p>
                    </li>
                    <li>
                        <p style={{fontWeight: "bold"}}>{t('UniSafeSmart.List_3')}</p>
                        <p>{t('UniSafeSmart.List_4')}</p>
                    </li>
                </ul>
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function CloverMoney ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('CloverMoney.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}}>
                <img src={Logo_CloverMoney} className="Product_Logo_Left"/>

                <p className="Text_description">{t('CloverMoney.Description_1')}</p>
                <p className="Text_description">{t('CloverMoney.Description_2')}</p>
                {/*<p className="Text_description">{t('CloverMoney.Description_3')}</p>*/}

            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function UniSafeTech ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '1' || TypeDesign === '3' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block">
            <h1 className="Heading">{t('UniSafeTech.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >

                <h2 className="Subtitle">{t('UniSafeTech.Subtitle')}</h2>
                <img src={Logo_UnisafeTech} className="Product_Logo"/>
                <p className="Text_body">{t('UniSafeTech.Body')}</p>

            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function About ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '4' || TypeDesign === '6' ? '0' : TypeDesign;

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 650);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 650);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const headingStyle = isSmallScreen ? { top: '-43px', width: '70%' } : {};

    const { t } = useTranslation();

    return (
        <div className="Main_block" style={{marginBottom: '100px'}}>
            <a name="About" className="Link" style={{position: "absolute", top: '-100px'}}></a>

            <h1 className="Heading_small" style={headingStyle}>{t('About.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}}>

                <p className="Text_description">{t('About.Description')}</p>

                <div className="Infographics">
                    <div className="Infographics_block">
                        <h1>{t('About.Indicator_1')}</h1>
                        <h2>{t('About.Label_1')}</h2>
                    </div>
                    <div className="Pointer_infographics_1"></div>
                    <div className="Infographics_block">
                        <h1>{t('About.Indicator_2')}</h1>
                        <h2>{t('About.Label_2')}</h2>
                    </div>
                    <div className="Pointer_infographics_2"></div>
                    <div className="Infographics_block">
                        <h1>{t('About.Indicator_3')}</h1>
                        <h2>{t('About.Label_3')}</h2>
                    </div>
                </div>

                <p className="Text_body">{t('About.Body_1')}</p>
                <p className="Text_body">{t('About.Body_2')}</p>
                <p className="Text_body">{t('About.Body_3')}</p>
                <p className="Text_body">{t('About.Body_4')}</p>
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function Partners ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '4' || TypeDesign === '6' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block" style={{marginBottom: '100px'}}>
            <h1 className="Heading_small" style={{marginLeft: '5%'}}>{t('Partners.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >
                <div className="Partners">
                    <LogoWithBack Image={Logo_MTS_BW} Title={'МТС'}/>
                    <LogoWithBack Image={Logo_MegaFon_BW} Title={'МегаФон'}/>
                    <LogoWithBack Image={Logo_Beeline_BW} Title={'Билайн'}/>
                    <LogoWithBack Image={Logo_Tele2_BW} Title={'Теле2'}/>
                    <LogoWithBack Image={Logo_Sber_BW} Title={'Сбер страхование'}/>
                    <LogoWithBack Image={Logo_Alfa_BW} Title={'Альфа страхование'}/>
                    <LogoWithBack Image={Logo_ATB_BW} Title={'АТБ'}/>
                    <LogoWithBack Image={Logo_OTP_BW} Title={'ОТП банк'}/>
                    <LogoWithBack Image={Logo_MVideo_BW} Title={'МВидео'}/>
                    <LogoWithBack Image={Logo_Eldorado_BW} Title={'Эльдорадо'}/>
                    <LogoWithBack Image={Logo_Mechta_BW} Title={'Мечта'}/>
                    <LogoWithBack Image={Logo_Evrika_BW} Title={'Эврика'}/>
                    <LogoWithBack Image={Logo_Samolet_BW} Title={'Самолет'}/>
                    <LogoWithBack Image={Logo_NowHow_BW} Title={'Ноу Хау'}/>
                </div>
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}

export function Contacts ({Design, TypeDesign, Price, Link}) {
    const margin = TypeDesign === '4' || TypeDesign === '6' ? '0' : TypeDesign;

    const { t } = useTranslation();

    return (
        <div className="Main_block" style={{marginBottom: '0'}}>

            <a name="Contacts" className="Link" style={{position: "absolute", top: '-100px'}}></a>
            <h1 className="Heading_small" style={{marginLeft: '-42%'}}>{t('Contacts.Heading')}</h1>
            <div className="Content_block" style={{ marginLeft: margin}} >
                <Contact Link={t('Contacts.Link_1')} Info={t('Contacts.Indicator_1')} Title={t('Contacts.Label_1')}/>
                <Contact Link={t('Contacts.Link_2')} Info={t('Contacts.Indicator_2')} Title={t('Contacts.Label_2')}/>
                <Contact Link={t('Contacts.Link_3')} Info={t('Contacts.Indicator_3')} Title={t('Contacts.Label_3')}/>
                {/*<iframe className="Map" src="https://yandex.ru/map-widget/v1/?ll=37.489355%2C55.764947&mode=whatshere&whatshere%5Bpoint%5D=37.489108%2C55.764986&whatshere%5Bzoom%5D=17&z=18.02"></iframe>*/}

                <iframe className="Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d943.7518047468525!2d37.487701882657056!3d55.76488210760407!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b54915ad01f6cb%3A0x7a8d332ec3abbd40!2z0K7QvdC40KHQtdC50YQ!5e0!3m2!1sru!2sus!4v1720519714021!5m2!1sru!2sus"
                    allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <div className="Design">
                <Design TypeDesign={TypeDesign} Link={Link}/>
            </div>
        </div>
    )
}